export const possibleAdvertPlacement = (advertType) => {
  if (advertType === 'general') {
    return [
      { label: 'Meeting Gaps', value: 'gaps' },

      { label: 'RP Zone', value: 'rpZone' },
    ]
  }
  if (advertType === 'meeting') {
    return [
      { label: 'Meeting Bar', value: 'meetingBar' },
      { label: 'Race', value: 'race' },
      { label: 'Meeting Specific Gaps', value: 'gaps' },
    ]
  }
  if (advertType === 'race_specific') {
    return [{ label: 'Race Specific', value: 'race_specific' }]
  }
  if (advertType === 'feature_race') {
    return [{ label: 'Column Gaps', value: 'gaps' }]
  }
  if (advertType === 'boyles_virtual') {
    return [{ label: 'Virtual Menu', value: 'virtual_menu' }]
  }
  return []
}

export const scopeIncludeEnum = {
  include: 'include',
  exclude: 'exclude',
}

export const jurisdictions = [
  { name: 'UK/IRE', include: 'include' },
  { name: 'USA', include: 'include' },
  { name: 'FR', include: 'include' },
  { name: 'SAF', include: 'include' },
  { name: 'UAE', include: 'include' },
]

export const getBase64 = (file) => {
  return new Promise(function (resolve, reject) {
    var reader = new FileReader()
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
